import { Box, Container, Grid, Typography } from "@mui/material";
import  AccountProfile  from "../components/account/account-profile";
import { AccountProfileDetails } from "../components/account/account-profile-details";
import { DashboardLayout } from "../components/dashboard-layout";
import { ThemeProvider } from "@mui/material/styles";

import { theme } from "../theme";

function Account() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <DashboardLayout>
          {
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                py: 8,
              }}
            >
              <Container maxWidth="lg">
                <Typography sx={{ mb: 3 }} variant="h4">
                  Tài khoản
                </Typography>
                <Grid container spacing={3}>
                  <Grid item lg={4} md={6} xs={12}>
                    <AccountProfile />
                  </Grid>
                  <Grid item lg={8} md={6} xs={12}>
                    <AccountProfileDetails />
                  </Grid>
                </Grid>
              </Container>
            </Box>
          }
        </DashboardLayout>
      </ThemeProvider>
    </>
  );
}


export default Account;
